import './navbar.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import config from '../../../config';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

export default function Navbar() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${config.API_URL}/login`, { username, password });
      localStorage.setItem('token', response.data.access_token);
      setIsLoggedIn(true);
      window.location.reload();
    } catch (error) {
      console.error('Login failed:', error);
      alert('Invalid credentials');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Language Switcher with Flags */}
      <div className="language-switcher">
        <img 
          src="https://www.countryflags.com/wp-content/uploads/germany-flag-png-xl.png" 
          alt="German" 
          className="flag" 
          onClick={() => changeLanguage('de')}
        />
        <img 
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN6NjUzMsxiPYELyWrKg17MA4eLo47fkkM2w&s" 
          alt="English" 
          className="flag" 
          onClick={() => changeLanguage('en')}
        />
        <img 
          src="https://cdn.britannica.com/82/682-004-F0B47FCB/Flag-France.jpg" 
          alt="French" 
          className="flag" 
          onClick={() => changeLanguage('fr')}
        />
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/2560px-Flag_of_Italy.svg.png" 
          alt="Italian" 
          className="flag" 
          onClick={() => changeLanguage('it')}
        />
      </div>


      <nav className="navbar">
        <div className="navbar-left">
          <NavLink className='logoimg' to='/'>
            <img src='/img/logo.png' alt="Logo" style={{ maxWidth: '272px' }} />
          </NavLink>
          <button className="hamburger" onClick={toggleMenu}>
            &#9776;
          </button>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <li>
              <NavLink to="/" activeClassName="active">Car Auction</NavLink>
            </li>
            <li>
              <NavLink to="/how-it-works" activeClassName="active">{t('howItWorksMenu')}</NavLink>
            </li>
            {!isLoggedIn && (
              <li>
                <NavLink to="/register" activeClassName="active">{t('registrationmenu')}</NavLink>
              </li>
            )}
            {isLoggedIn && (
              <>
              <li>
                <NavLink to="/mycars" activeClassName="active">{t('myCarsmenu')}</NavLink>
              </li>
              <li>
                <NavLink to="/myaccount" activeClassName="active">{t('myAccount')}</NavLink>
              </li>
              </>
            )}
          </ul>
        </div>
        <div className={`navbar-right ${isMenuOpen ? 'open' : ''}`}>
          {isLoggedIn ? (
            <button className='signout' onClick={handleLogout}>{t('signOutButton')}</button>
          ) : (
            <form className="login-form" onSubmit={handleLogin}>
              <div className='upbuttons'>
                <input 
                  type="text" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                  placeholder="Username" 
                />
                <input 
                  type="password" 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  placeholder="Password" 
                />
              </div>
              <div className='downbuttons'>
                <a href="/forgotpassword" className="forgot-password">{t('lostYourPassword')}</a>
                <button type="submit">{t('signInButton')}</button>
              </div>
            </form>
          )}
             {isMenuOpen && (
          <div className="mobile-language-switcher">
            <img
              src="https://www.countryflags.com/wp-content/uploads/germany-flag-png-xl.png"
              alt="German"
              className="flag"
              onClick={() => changeLanguage('de')}
            />
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN6NjUzMsxiPYELyWrKg17MA4eLo47fkkM2w&s"
              alt="English"
              className="flag"
              onClick={() => changeLanguage('en')}
            />
            <img
              src="https://cdn.britannica.com/82/682-004-F0B47FCB/Flag-France.jpg"
              alt="French"
              className="flag"
              onClick={() => changeLanguage('fr')}
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/2560px-Flag_of_Italy.svg.png"
              alt="Italian"
              className="flag"
              onClick={() => changeLanguage('it')}
            />
          </div>
        )}
        </div>
      </nav>
    </>
  );
}
