// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Registrationform {
    width: auto !important;
    max-width: -webkit-fill-available !important;
}

.tableforregistration {
    box-shadow: none !important;
}

.tableforregistration h6{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.registrationlabel {
    font-family: 'Poppins', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Register.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAA4C;AAChD;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,kCAAkC;IAClC,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".Registrationform {\n    width: auto !important;\n    max-width: -webkit-fill-available !important;\n}\n\n.tableforregistration {\n    box-shadow: none !important;\n}\n\n.tableforregistration h6{\n    font-family: 'Poppins', sans-serif;\n    font-weight: bold;\n}\n\n.registrationlabel {\n    font-family: 'Poppins', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
