// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: flex;
    justify-content: center;
    column-gap: 7%;
    margin-top: 5%;
    padding-bottom: 3%;
    background-color: #494944;
    color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-top: 5%;
    font-family: 'Poppins', sans-serif;
}

.footer h5 {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}

@media (max-width: 480px) {
    .footer {
        align-items: center;
        display: grid;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;IAC5B,6BAA6B;IAC7B,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,iBAAiB;IACjB,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI;QACI,mBAAmB;QACnB,aAAa;IACjB;AACJ","sourcesContent":[".footer {\n    display: flex;\n    justify-content: center;\n    column-gap: 7%;\n    margin-top: 5%;\n    padding-bottom: 3%;\n    background-color: #494944;\n    color: white;\n    border-top-left-radius: 30px;\n    border-top-right-radius: 30px;\n    padding-top: 5%;\n    font-family: 'Poppins', sans-serif;\n}\n\n.footer h5 {\n    font-weight: bold;\n    font-family: 'Poppins', sans-serif;\n    font-size: 20px;\n}\n\n@media (max-width: 480px) {\n    .footer {\n        align-items: center;\n        display: grid;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
