import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCarById } from '../services/fetchData';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './EditCar.css';
import Header from '../components/header/Header';
import axios from 'axios';
import config from '../config';

export default function EditCar() {
  const { id } = useParams(); // Get car ID from route params
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  const [carData, setCarData] = useState({
    car_name: '',
    car_liters: '',
    car_accident: '',
    minimumprice: '',
    specifications: {}, // State to hold the specifications object
    car_images: [],
  });
  const [message, setMessage] = useState(''); // State for success message
  const [error, setError] = useState(''); // State for error message

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        const data = await fetchCarById(id); // Fetch car details by ID from backend
        setCarData({
          car_name: data.specifications.car_name,
          car_liters: data.specifications.car_liters,
          car_accident: data.specifications.car_accident,
          minimumprice: data.minimumprice,
          specifications: data.specifications, // Set the specifications from response
          car_images: data.car_images || [],
        });
      } catch (error) {
        navigate('/');
        // console.error('Error fetching car details:', error);
      }
    };

    getCarDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarData({
      ...carData,
      [name]: value,
    });
  };

  // Handle change for specifications inputs
  const handleSpecificationsChange = (e) => {
    const { name, value } = e.target;
    setCarData({
      ...carData,
      specifications: {
        ...carData.specifications,
        [name]: value,
      },
    });
  };

  const handleImageChange = (e) => {
    setSelectedImages([...e.target.files]); // Store selected files
  };

  const handleDeleteImage = async (imagePath) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${config.API_URL}/deletecarimage/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: { image_path: imagePath }, // Send the image path to delete
      });
      setMessage(response.data.message);
      // Remove the deleted image from the carData state
      setCarData((prevState) => ({
        ...prevState,
        car_images: prevState.car_images.filter((image) => image !== imagePath),
      }));
    } catch (err) {
      setError(err.response.data.error || 'An error occurred while deleting the image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(); // Create FormData object

    // Append car data to FormData
    formData.append('car_specifications', JSON.stringify(carData.specifications));
    formData.append('minimum_price', carData.minimumprice);
    for (const image of selectedImages) {
      formData.append('images', image); // Append each selected image
    }
    try {
      const token = localStorage.getItem('token'); // Assuming the JWT token is stored in localStorage

      const response = await axios.put(`${config.API_URL}/updatecarinfo/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setMessage(response.data.message);
      navigate(`/mycars/`); // Redirect to the car details page after success
    } catch (err) {
      setError(err.response.data.error || 'An error occurred while updating the car');
    }
  };

  return (
    <>
      <Header />
      <div className="editcar-container">
        <h2>Edit Car</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Car Name"
            name="car_name"
            value={carData.car_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Car Liters"
            name="car_liters"
            value={carData.car_liters}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Car Accident"
            name="car_accident"
            value={carData.car_accident}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Minimum Price"
            name="minimumprice"
            value={carData.minimumprice}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Specifications Section */}
          <h3>Specifications</h3>
          <TextField
            label="Body"
            name="Body"
            value={carData.specifications.Body || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fuel"
            name="Fuel"
            value={carData.specifications.Fuel || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Gear"
            name="Gear"
            value={carData.specifications.Gear || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="1st Reg"
            name="1st reg"
            value={carData.specifications["1st reg"] || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mileage"
            name="Mileage"
            value={carData.specifications.Mileage || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Interior"
            name="Interior"
            value={carData.specifications.Interior || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Exterior Colour"
            name="Exterior_colour"
            value={carData.specifications.Exterior_colour || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Number of Seats"
            name="Number of seats"
            value={carData.specifications["Number of seats"] || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Wheel Drive"
            name="Wheel drive"
            value={carData.specifications["Wheel drive"] || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Displacement"
            name="Displacement"
            value={carData.specifications.Displacement || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Car Finish"
            name="carfinish"
            value={carData.specifications.carfinish || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mechanics"
            name="mechanics"
            value={carData.specifications.mechanics || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Number of Keys"
            name="numberOfKeys"
            value={carData.specifications.numberOfKeys || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Service Record Booklet"
            name="serviceRecordBooklet"
            value={carData.specifications.serviceRecordBooklet || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Vehicle Inspection"
            name="vehicleInspection"
            value={carData.specifications.vehicleInspection || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Vehicle Registration Document"
            name="vehicleRegistrationDocument"
            value={carData.specifications.vehicleRegistrationDocument || ''}
            onChange={handleSpecificationsChange}
            fullWidth
            margin="normal"
          />

          {/* Car Images */}
          <div className="car-images">
            <label>Car Images</label>
            {carData.car_images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image} alt={`Car ${index + 1}`} style={{ width: '100px', margin: '10px' }} />
                <Button variant="outlined" color="error" onClick={() => handleDeleteImage(image)}>
                  Delete
                </Button>
              </div>
            ))}
          </div>
          <input type="file" multiple onChange={handleImageChange} />
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </form>

        {/* Display success or error message */}
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
}
