import './footer.css';

export default function Footer() {
    return (
        <div className='footer'>
            <div>
                {/* <img src='/img/logo.png' alt='companylogo'></img> */}
                <p>Wyttenbach Cars AG</p>
                <p>Hellbühlstrasse 9</p>
                <p>6102, Malters</p>
                <p>E-Mail info@autoauktion24.ch</p>
            </div>
            <div>
                <h5>Auto Auktion</h5>
                <a href="/how-it-works"><p>About US</p></a>
                <p>Jobs</p>
                <p>Imprint</p>
            </div>
            <div>
                <h5>How it works</h5>
                <p>Instructions</p>
                <p>Prices</p>
                <p>GC</p>
                <p>DPR</p>
            </div>
            <div>
                <h5>Associated member of the</h5>
                <p>Swiss Leasing Association</p>
                <img src="https://www.AutoAuction24.ch/img/slvassl_logo.jpg" width={100} alt='Swiss Leasing Association'></img>
            </div>
        </div>
    )
}

