import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/header/Header';
import Counter from '../components/car/counter';
import { fetchData } from '../services/fetchData';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import io from 'socket.io-client';
import CarImageGallery from '../components/car/carimagegallery';
import './Car.css';
import CountDown from '../components/homepage/countdown';
import Cartoggleinfo from '../components/car/cartoggleinfo';
import { useTranslation } from 'react-i18next';
import config from '../config';

const socket = io('https://autoauction24.ch', {
  path: '/api/socket.io'
});

export default function Car() {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [currentBidPrice, setCurrentBidPrice] = useState(null);
  const [currentWinner, setCurrentWinner] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {t} = useTranslation();

  const accordionData = [
    {
      title: t('specialEquipmentTitle'),
      details: `${car?.special_equipment || ''}`,
      expandIcon: "ArrowDownwardIcon"
    },
    {
      title: t('serialEquipmentTitle'),
      details: `${car?.serial_equipment || ''}`,
      expandIcon: "ArrowDropDownIcon"
    },
    {
      title: t('vehicleDescriptionTitle'),
      details: {
        columns: [
          [
            `${t('repairsTitle')} - ${car?.specifications?.repairamount || ''}`,
            `${t('accidentTitle')} - ${car?.specifications?.car_accident || ''}`,
            `${t('vehicleInspection')} - ${car?.specifications?.vehicleInspection || ''}`,
          ],
          [
            `${t('serviceRecordBooklet')} - ${car?.specifications?.serviceRecordBooklet || ''}`,
            `${t('vehicleRegistrationDocument')} - ${car?.specifications?.vehicleRegistrationDocument || ''}`,
            `${t('numberOfKeys')} - ${car?.specifications?.numberOfKeys || ''}`,
          ],
          [
            "Model no - 1aa234"
          ]
        ]
      },
      expandIcon: "ArrowDropDownIcon"
    },
    {
      title: t('conditionTitle'),
      details: {
        columns: [
          [
            `${t('mechanics')} - ${car?.specifications?.mechanics || ''}`,
            `${t('carFinish')} - ${car?.specifications?.carfinish || ''}`,
            `${t('bodyTitle')} - ${car?.specifications?.Body || ''}`,
          ]
        ]
      },
      expandIcon: "ArrowDropDownIcon"
    }
  ];


  // Define handleBidUpdates outside of useEffect
  const handleBidUpdates = async () => {
    console.log('Bid placed. Handling bid update...');
    // Re-fetch car data
    const data = await fetchData();
    const carData = data[0].cars.find((c) => c.id === parseInt(id));
    setCar(carData);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    const getCarData = async () => {
      const data = await fetchData();
      setStartDate(data[0].startdate);
      setEndDate(data[0].enddate);
      const carData = data[0].cars.find((c) => c.id === parseInt(id));
      setCar(carData);
    };

    socket.connect();

    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
    });

    const handleBidUpdate = (data) => {
      console.log('New price updated:', data.bid);
      console.log('Updated by:', data.updated_by);
      setCurrentBidPrice(data.bid);
      setCurrentWinner(data.updated_by);
      handleBidUpdates(); // Call handleBidUpdates to refresh car data
    };

    socket.on('bid_update', handleBidUpdate);

    getCarData();

    return () => {
      socket.off('bid_update', handleBidUpdate);
      socket.disconnect();
    };
  }, [id]);

  if (!car) {
    return <div>Loading...</div>;
  }

  const excludeKeys = [
    'number of seat',
    'car_accident',
    'car_liters',
    'car_name',
    'vehicleinspection',
    'numberofkeys',
    'vehicleregistrationdocument',
    'servicerecordbooklet',
    'mechanics',
    'carfinish',
    'carmasternumber',
    'carmodel',
    'generalcondition',
    'mechanics',
    'numberOfKeys',
    'repairamount',
    'serviceRecordBooklet',
    'serviceelectrical',
    'vehicleInspection',
    'vehicleRegistrationDocument',
    'vehicleidentificationnumber'
  ];

  const handleExpertReportClick = async () => {
      try {
          const response = await fetch(`${config.API_URL}/expertreport/${car.id}`);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          window.location.assign(`${config.API_URL}/expertreport/${car.id}`, '_blank');
      } catch (error) {
          console.error('Error fetching expert report:', error);
          alert("Failed to fetch expert report.");
      }
  };

  return (
    <>
      <Header />
      <div className='mainheaderh3'>
        <h3 style={{fontFamily: 'Poppins', fontWeight: 'bold'}}>{car.specifications.car_name}, {car.specifications.Body}, {car.specifications['1st reg']}</h3>
      </div>
      <div className="car-container">
        <div className="car-column">
          <CarImageGallery carId={id} />
          {car.expertreport && (
            <button onClick={handleExpertReportClick}>
              Expert Report
            </button>
          )}
        </div>
        <div className="car-column">
          <TableContainer component={Paper}>
            <Table aria-label="car details table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#f3f3f3' }}>
                  <TableCell style={{ borderTopLeftRadius: 30, background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold'}}>{t('carTableSpecifications')}</TableCell>
                  <TableCell style={{ borderTopRightRadius: 30, background: 'yellow', color: 'black', fontFamily: 'Poppins', fontWeight: 'bold'}} align="right">{t('carDetailsSpecifications')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(car.specifications)
                  .filter(([key]) => !excludeKeys.includes(key.toLowerCase()))
                  .map(([key, value]) => (
                    <TableRow key={key} style={{ fontFamily: 'Poppins' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ paddingLeft: '2%', lineHeight: '1', fontFamily: 'Poppins' }}
                      >
                        {t(key.replace('_', ' ').toUpperCase())}
                      </TableCell>
                      <TableCell style={{ fontFamily: 'Poppins' }} align="right">
                        {value}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="car-column">
          {isLoggedIn ? (
            <>
              <div>
                {car.currentbidprice > 0 ? (
                  <>
                    <div className='countdownincarevent'>
                      <CountDown targetDate={startDate} endTargetDate={endDate}/>
                    </div>
                    <Counter 
                      car={car} 
                      currentBidPrice={currentBidPrice} 
                      minimumprice={car.minimumprice} 
                      onBidUpdate={handleBidUpdates} 
                    />
                    <p style={{fontFamily: 'Poppins'}}>{t('highestBidPlacedBy')} <span style={{fontWeight: 'bold'}}>{currentWinner ? currentWinner : car.bidwinner}</span></p>
                  </>
                ) : (
                   <CountDown targetDate={startDate} endTargetDate={endDate}/>
                )}
              </div>
            </>
          ) : (
            <>
              <CountDown targetDate={startDate} endTargetDate={endDate}/>
              <p style={{width: '80%', margin: 'auto'}}>{t('loginToSeePrice')}</p>
            </>
          )}
        </div>
      </div>
      <Cartoggleinfo accordionData={accordionData} />
    </>
  );
}
