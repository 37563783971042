import './Banner.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, Trans } from 'react-i18next';

export default function Banner() {
    const { t, i18n } = useTranslation();
    return (
        <div className="container-fluid bannerdiv">
            <div className="row">
                <div className="col-md-12 leftbannerdiv">
                    <div>
                        <h3>{t('mainheadertitle')}</h3>
                        <p><Trans i18nKey="mainheaderparagraph"></Trans></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
