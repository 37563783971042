import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import './Countdown.css';
import { useTranslation } from "react-i18next";

export default function CountDown({ targetDate, endTargetDate }) {
  const [time, setTime] = useState(0);
  const [isEventStarted, setIsEventStarted] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = moment().tz("Europe/Berlin"); // CEST timezone
      const target = moment.tz(targetDate, "ddd, DD MMM YYYY HH:mm:ss GMT", "Europe/Berlin");
      const endTarget = moment.tz(endTargetDate, "ddd, DD MMM YYYY HH:mm:ss GMT", "Europe/Berlin");

      let difference;
      if (now.isAfter(target)) {
        // If the current time is after the start date, calculate the time left until the end date
        difference = endTarget.diff(now);
        setIsEventStarted(true); // Set flag to indicate event has started
      } else {
        // If the current time is before the start date, calculate the time left until the start date
        difference = target.diff(now);
        setIsEventStarted(false); // Set flag to indicate event has not started
      }

      return Math.max(0, Math.floor(difference / 1000)); // Calculate time difference in seconds
    };

    setTime(calculateTimeLeft());

    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        } else return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [targetDate, endTargetDate]);

  return (
    <p className="eventcountdown">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iOSIgc3Ryb2tlPSIjNEY0RjRGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTAgNC4xNjY5OVYxMC4yNzgxTDEzLjMzMzMgMTMuMzMzNyIgc3Ryb2tlPSIjNEY0RjRGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
        alt="Clock Icon"
        style={{ verticalAlign: "middle", marginRight: "5px" }}
        width="20"
        height="20"
      />
      {isEventStarted ? t('eventEndsIn') : t('auctionStartsIn')}{" "}
      <span style={{ fontWeight: "bold", color: "black", fontFamily: "Poppins" }}>
        {`${Math.floor(time / 3600)}`.padStart(2, '0')}:
        {`${Math.floor((time % 3600) / 60)}`.padStart(2, '0')}:
        {`${time % 60}`.padStart(2, '0')}
      </span>
    </p>
  );
}
