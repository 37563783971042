import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { fetchData } from '../../services/fetchData';
import CountDown from './countdown';
import './BasicTable.css';
import config from '../../config';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';
import Spinner from '../spinner';

export default function BasicTable() {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [filterText, setFilterText] = useState('');
  const [filterMileage, setFilterMileage] = useState('');
  const [filterYear, setfilterYear] = useState('');
  const [filterPrice, setfilterPrice] = useState('');
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);

    const getData = async () => {
      setLoading(true);
      const data = await fetchData();
      if (data && data.length === undefined) {
        setStartDate(data.startdate);
        setEndDate(data.enddate);
        setRows(data.cars || []);
        setLoading(false);
      } else if (data && data.length > 0) {
        setStartDate(data[0].startdate);
        setEndDate(data[0].enddate);
        setRows(data[0].cars || []);
        setLoading(false);
      }
    };
    getData();

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRowClick = (id) => {
    navigate(`/car/${id}`);
  };

  const filteredRows = rows.filter(row => {
    const carName = row.specifications?.car_name || '';
    const mileage = row.specifications?.Mileage || '';
    const carminimumprice = row.minimumprice || '';
    const regDate = row.specifications?.['1st reg'] || '';
    
    // Extract the year from "MM/YYYY"
    const regYear = regDate.split('/')[1];
    
    const matchesName = carName.toLowerCase().includes(filterText.toLowerCase());
    const matchesMileage = filterMileage === '' || Number(mileage) <= filterMileage;
    const matchesPrice = filterPrice === '' || Number(carminimumprice) <= filterPrice;
    const matchesYear = filterYear === '' || regYear === filterYear;
    
    return matchesName && matchesMileage && matchesYear && matchesPrice;
  });

  const renderTable = () => (
    <>
      <Spinner loading={loading}/>
      <TableContainer component={Paper} className="table-container">
        <Table className="mui-table" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className='tableth' style={{ backgroundColor: 'yellow', color: 'black' }}>
              <TableCell>{t('imageInTable')}</TableCell>
              <TableCell>{t('vehicleInTable')}</TableCell>
              <TableCell align="right">{t('firstVehicleRegistration')}</TableCell>
              <TableCell align="right">{t('kmInTable')}</TableCell>
              <TableCell align="right">{t('minimumPriceInTable')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">{t('noCarsAvailable')}</TableCell>
              </TableRow>
            ) : (
              filteredRows.map((row) => {
                const hasValidData = row.specifications && row.specifications.car_name;
                const carName = row.specifications?.car_name || 'Unknown Car Name';
                const firstImage = row.first_image ? `${config.API_URL}${row.first_image}` : '';
                const regDate = row.specifications?.['1st reg'] || 'N/A';
                const mileage = row.specifications?.['Mileage'] || 'N/A';
                const minPrice = isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice');

                return (
                  <TableRow key={row.id} onClick={hasValidData ? () => handleRowClick(row.id) : undefined} style={{ cursor: hasValidData ? 'pointer' : 'default' }}>
                    <TableCell>
                      <div className="image-container">
                        {firstImage ? (
                          <>
                            <img src={firstImage} alt={carName} className="thumbnail" />
                            <div className="overlay">
                              <img src={firstImage} alt={carName} className="enlarged" />
                            </div>
                          </>
                        ) : (
                          <span>{t('noImageAvailable')}</span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{carName}</TableCell>
                    <TableCell align="right">{regDate}</TableCell>
                    <TableCell align="right">{mileage}</TableCell>
                    <TableCell align="right">{minPrice}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const renderCard = (row) => (
    <div key={row.id} className="card" onClick={() => handleRowClick(row.id)}>
      <div className="card-header">
        <img src={row.first_image ? `${config.API_URL}${row.first_image}` : ''} alt={row.specifications?.car_name || 'Unknown Car'} />
      </div>
      <div className="card-body">
        <h3>{row.specifications?.car_name || 'Unknown Car Name'}</h3>
        <p><strong>{t('firstVehicleRegistration')}:</strong> {row.specifications?.['1st reg'] || 'N/A'}</p>
        <p><strong>{t('kmInTable')}:</strong> {row.specifications?.['Mileage'] || 'N/A'}</p>
        <p><strong>{t('minimumPriceInTable')}:</strong> {isLoggedIn ? (row.minimumprice !== null ? row.minimumprice : 'N/A') : t('loginToSeePrice')}</p>
      </div>
    </div>
  );

  return (
    <>
      <CountDown targetDate={startDate} endTargetDate={endDate} />
      {isLoggedIn && (

      <div className='filters'>
        <h3>Filter</h3>
        <div className='filtersdiv'>
          <TextField
            label={t("carNameFilter")}
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <TextField
            label={t("mileageGreaterThan")}
            variant="outlined"
            value={filterMileage}
            onChange={(e) => setFilterMileage(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>{t('firstVehicleRegistration')}</InputLabel>
            <Select
              value={filterYear}
              onChange={(e) => setfilterYear(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t("priceMaximum")}
            variant="outlined"
            value={filterPrice}
            onChange={(e) => setfilterPrice(e.target.value)}
          />
        </div>
      </div>
      )}
      {isMobile ? (
        <div className="cards-container">
          {filteredRows.map((row) => renderCard(row))}
        </div>
      ) : (
        renderTable()
      )}
    </>
  );
}
