import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import './Register.css';
import Spinner from '../components/spinner';
import {
    Container,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import Header from '../components/header/Header';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const Register = () => {
    const [passwordError, setPasswordError] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        company: '',
        addition: '',
        streetNo: '',
        poBox: '',
        postcode: '',
        town: '',
        country: '',
        carDealership: '',
        motorcycleDealership: '',
        commercialVehicleDealership: '',
        firstName: '',
        surname: '',
        email: '',
        telephone: '',
        mobile: '',
        preferredLanguage: '',
        termsAccepted: false
    });
    const [isIndividual, setIsIndividual] = useState(false);
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/;


    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (name === 'password') {
            const isValid = passwordRegex.test(value);
            setPasswordError(!isValid);
            setIsPasswordValid(isValid);
        }
    };

    const handleRegister = async () => {
        setLoading(true);
        const businessRequiredFields = [
            'username',
            'password',
            'streetNo',
            'postcode',
            'town',
            'country',
            'firstName',
            'surname',
            'email',
            'telephone'
        ];

        const individualRequiredFields = [
            'username',
            'password',
            'firstName',
            'surname',
            'email',
            'telephone'
        ];

        const requiredFields = isIndividual ? individualRequiredFields : businessRequiredFields;
    
        const missingFields = requiredFields.filter(field => !formData[field]);
    
        if (missingFields.length > 0) {
            alert(`Please fill out the following required fields: ${missingFields.join(', ')}`);
            setLoading(false);
            return;
        }

        // Check if terms are accepted
        if (!formData.termsAccepted) {
            alert('You must accept the terms and conditions');
            setLoading(false);
            return;
        }
    
        try {
            await axios.post(`${config.API_URL}/register`, formData);
            alert('User registered successfully. Our management will review your details quickly and will approve your registration.');
            navigate('/');
        } catch (error) {
            setLoading(false);
            console.error('Registration failed:', error);
            if (error.response && error.response.data && error.response.data.error === "Email already registered") {
                alert('This email already exists. Please use another email.');
            } else if (error.response && error.response.data && error.response.data.error === "Username already registered") {
                alert('This username already exists. Please use another username.');
            }   else {
                alert('Registration failed');
            }
        }
    };

    return (
        <>
            <Header />
            <Spinner loading={loading} />
            <Container component="main" maxWidth="md" className='Registrationform'>
            <p style={{ width: '80%', margin: 'auto', marginTop: '10vh', marginBottom: '2vh' }}>
                <Trans
                    i18nKey="youRregistrationRequest"
                    components={{ strong: <strong /> }}
                />
            </p>
                <Paper elevation={3} sx={{ padding: 3 }} className='tableforregistration'>
                    <Grid container spacing={2}>
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>{t('accountDetailsRegistration')}</Typography>
                            <TextField
                                fullWidth
                                label={t('registerpageusername')}
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                InputLabelProps={{ className: 'registrationlabel' }}
                            />
                           <TextField
                                fullWidth
                                label={t('registerpagepassword')}
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                error={formData.password.length > 0 && passwordError}
                                helperText={
                                    formData.password.length === 0
                                    ? "Password must be at least 6 characters long, include one uppercase letter, one number, and one special character."
                                    : passwordError
                                    ? "Password must be at least 6 characters long, include one uppercase letter, one number, and one special character."
                                    : "Password meets the requirements."
                                }
                                FormHelperTextProps={{
                                    style: {
                                    color:
                                        formData.password.length === 0
                                        ? 'grey'
                                        : passwordError
                                        ? 'red'
                                        : 'green',
                                    },
                                }}
                                />
                            <Typography variant="h6" gutterBottom>{t("personalInformationRegistration")}</Typography>
                            <TextField
                                fullWidth
                                label={t('registerpagefirstname')}
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                InputLabelProps={{ className: 'registrationlabel' }}
                            />
                            <TextField
                                fullWidth
                                label={t('registerpagesurname')}
                                name="surname"
                                value={formData.surname}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                InputLabelProps={{ className: 'registrationlabel' }}
                            />
                            <TextField
                                fullWidth
                                label={t('registerpageemail')}
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                InputLabelProps={{ className: 'registrationlabel' }}
                            />
                            <TextField
                                fullWidth
                                label={t('registerpagetelephone')}
                                name="telephone"
                                value={formData.telephone}
                                onChange={handleChange}
                                variant="outlined"
                                required
                                sx={{ mb: 2 }}
                                InputLabelProps={{ className: 'registrationlabel' }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isIndividual"
                                        checked={isIndividual}
                                        onChange={(e) => setIsIndividual(e.target.checked)}
                                    />
                                }
                                label={t('registerpagecheckboxforindividual')}
                            />
                        </Grid>

                        {/* Vertical Divider */}
                        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 1, borderColor: '#1D1E42', mx: 2 }} />

                        {!isIndividual ? (
                            <>
                                <Grid item xs={12} md={5}>
                                    <Typography variant="h6" gutterBottom>{t('companyInformationRegistration')}</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label={t('registerpagecompany')}
                                                name="company"
                                                value={formData.company}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label={t('registerpageaddition')}
                                                name="addition"
                                                value={formData.addition}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label={t('registerpagestreetno')}
                                                name="streetNo"
                                                value={formData.streetNo}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label="PO Box"
                                                name="poBox"
                                                value={formData.poBox}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label={t('registerpagepostcode')}
                                                name="postcode"
                                                value={formData.postcode}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label={t('registerpagetown')}
                                                name="town"
                                                value={formData.town}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <FormControl fullWidth variant="outlined" required sx={{ mb: 2 }}>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                    label={t('registerpagecountry')}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                maxWidth: '20px',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="switzerland">Switzerland</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                fullWidth
                                                label={t('registerpagecardealership')}
                                                name="carDealership"
                                                value={formData.carDealership}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label={t('registerpagemotordealership')}
                                                name="motorcycleDealership"
                                                value={formData.motorcycleDealership}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label={t('registerpagecommercialvehicledealership')}
                                                name="commercialVehicleDealership"
                                                value={formData.commercialVehicleDealership}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <TextField
                                                fullWidth
                                                label={t('registerpagepreferredlanguage')}
                                                name="preferredLanguage"
                                                value={formData.preferredLanguage}
                                                onChange={handleChange}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                                InputLabelProps={{ className: 'registrationlabel' }}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="termsAccepted"
                                                        checked={formData.termsAccepted}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                }
                                                label={t('registerpagetermsandconditions')}
                                                sx={{ mb: 2 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                onClick={handleRegister}
                                                style={{background: 'yellow', color:'black', borderRadius: '30px', width: '30%', fontFamily: 'Poppins'}}
                                            >
                                                {t('registerpagesubmitbutton')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12} md={5}>
                            <Typography variant="h6" gutterBottom>Terms & Conditions</Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="termsAccepted"
                                        checked={formData.termsAccepted}
                                        onChange={handleChange}
                                        required
                                    />
                                }
                                label="I accept the terms and conditions"
                                sx={{ mb: 2 }}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleRegister}
                                style={{background: 'yellow', color:'black', borderRadius: '30px', width: '30%', fontFamily: 'Poppins'}}
                            >
                                Submit
                            </Button>
                        </Grid>
                        )}
                        {/* Right Column */}
                    </Grid>
                </Paper>
            </Container>
        </>
    );
};

export default Register;
